import React, { useEffect, useReducer } from 'react';
import SideBar from './Sidebar';
import AlarmsTable from './AlarmsTable';
import { RotateSpinner } from 'react-spinners-kit';
import AlarmsForm from './AlarmsForm';
import { getAlarms } from '../../api';
import alarmsReducer from '../../reducers/alarms';
import Footer from './Footer';

const Alarms = ({ createAlarmsRequest }) => {
  const [alarms, dispatch] = useReducer(alarmsReducer, []);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch({ type: 'GET_ALARMS_REQUEST' });
      const initFetch = async () => {
        const data = await getAlarms({ token });
        dispatch({ type: 'GET_ALARMS_SUCCESS', payload: data });
      };
      initFetch();
    }
  }, []);

  return (
    <>
      <SideBar />
      <div className="md:ml-64 min-h-screen">
        <div className="main-content flex-1 bg-gray-100 mt-12 md:mt-2 pb-24 md:pb-5 min-h-screen">
          <AlarmsForm
            createAlarmsRequest={createAlarmsRequest}
            dispatch={dispatch}
          />
          <div className="flex flex-wrap">
            {alarms.isFetching ? (
              <div className="m-auto w-32 mt-6">
                <RotateSpinner
                  size={100}
                  color="#686769"
                  loading={alarms.isFetching}
                />
              </div>
            ) : (
              <AlarmsTable loading={true} data={alarms.data} />
            )}
          </div>
        </div>
        <Footer sticky={true} />
      </div>
    </>
  );
};

export { Alarms as default };
