import React, { useEffect, useReducer } from 'react';
import { RotateSpinner } from 'react-spinners-kit';
import { getQueries } from '../../api';
import queriesReducer from '../../reducers/queries';
import QueriesTable from './QueriesTable';

const QueriesBox = ({ amount }) => {
  const [queries, dispatch] = useReducer(queriesReducer, []);
  useEffect(() => {
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    if (token && userId) {
      dispatch({ type: 'GET_QUERIES_REQUEST' });
      const initFetch = async () => {
        const data = await getQueries({
          token: token,
          query: {
            sortBy: 'created:desc',
            limit: amount,
            user: userId,
          },
        });
        dispatch({ type: 'GET_QUERIES_SUCCESS', payload: data });
      };
      initFetch();
    }
  }, []);

  return (
    <>
      {queries.isFetching ? (
        <div className="m-auto w-32 mt-6">
          <RotateSpinner
            size={100}
            color="#686769"
            loading={queries.isFetching}
          />
        </div>
      ) : (
        <QueriesTable amount={amount} data={queries.data} />
      )}
    </>
  );
};

export { QueriesBox as default };
