import {
  GET_ALARMS_REQUEST,
  GET_ALARMS_SUCCESS,
  GET_ALARMS_FAILURE,
  CREATE_ALARMS_FAILURE,
  CREATE_ALARMS_SUCCESS,
  CREATE_ALARMS_REQUEST,
} from '../actions/alarms';

export const initialState = {
  data: [],
  isFetching: false,
  errorMessage: null,
  status: null,
};

const alarmsReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case GET_ALARMS_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: [],
        errorMessage: null,
      };
    case GET_ALARMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        errorMessage: null,
      };
    case GET_ALARMS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        errorMessage: error,
        isFetching: false,
      };
    case CREATE_ALARMS_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: [],
        errorMessage: null,
      };
    case CREATE_ALARMS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [...state.data, action.payload],
        errorMessage: null,
      };
    case CREATE_ALARMS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        errorMessage: error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default alarmsReducer;
