const theme = {
  input: {
    styles: {
      text: 'text-white-500',
      base: {
        container: {
          class: "hidden md:block",
          position: 'relative',
          width: 'w-full',
        },
      },
    },
  },
};

export default theme;
