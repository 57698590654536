import { connect } from "react-redux";
import SignUpPage from "../components/SignUpPage";
import { userSignUp } from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    userSignUp: (email, password) => dispatch(userSignUp(email, password)),
  };
};

const mapStatetoProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(SignUpPage);
