import React from 'react';
import { Button } from '@material-tailwind/react';

const Hero = () => {
  return (
    <div class="pt-24">
      <div class="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
        <div class="flex flex-col w-full md:w-2/5 justify-center items-start text-center md:text-left">
          <p class="uppercase tracking-loose w-full">Validación de Cheques</p>
          <h1 class="my-4 text-5xl font-bold leading-tight">
            Fuente de Datos de Cheques en Argentina
          </h1>
          <p class="leading-normal text-2xl mb-8">
            Puedes consultar la trazabilidad de un cheque emitido por un banco
            con sólo unos clicks.
          </p>
          <a href="/signup">
            <Button>Crear Cuenta</Button>
          </a>
        </div>
        <div class="w-full md:w-3/5 py-6 text-center">
          <img class="w-full md:w-4/5 z-50" src={'/hero.png'} />
        </div>
      </div>
    </div>
  );
};
export default Hero;
