import React from "react";
import TableRow from "./TableRowTransactions";
const TransactionsTable = ({ data }) => {
  return (
    data &&
    data.checks.length != 0 && (
      <table className="table-auto mx-2 mt-4 text-black bg-white">
        <thead>
          <tr>
            <th className="hidden md:block px-4 py-2">Nro. de Cheque</th>
            <th className="px-4 py-2">Monto</th>
            <th className="px-4 py-2">Motivo de Rechazo</th>
            <th className="px-4 py-2">Fecha de Rechazo</th>
            <th className="px-4 py-2">Fecha de Pago</th>
          </tr>
        </thead>
        <tbody>
          {data.checks.map((item) => (
            <TableRow key={item.number} {...item} />
          ))}
        </tbody>
      </table>
    )
  );
};

export default TransactionsTable;
