import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import './assets/styles/index.css'

import { ThemeProvider } from "@material-tailwind/react";
import theme from "./assets/styles/theme";

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider value={theme}>
    <App />
  </ThemeProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
