import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
  Chip,
} from '@material-tailwind/react';
import { formatRelative, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const QueriesTable = ({ amount, data }) => {
  return (
    <Card>
      <CardHeader
        variant="gradient"
        color="purple"
        className="mx-0 -mt-10 mb-4 grid items-center h-24 py-4 px-8 justify-start"
      >
        <Typography className="uppercase text-gray-200 text-xs font-medium">
          Top {amount}
        </Typography>
        <Typography className="text-white text-2xl">
          Uĺtimas Búsquedas
        </Typography>
      </CardHeader>
      <CardBody className="overflow-x-scroll px-0 pt-0 pb-2">
        <table className="w-full min-w-[640px] table-auto">
          <thead>
            <tr>
              {['Empresa', 'CUIT', 'Fecha'].map((el) => (
                <th
                  key={el}
                  className="border-b border-blue-gray-50 py-3 px-5 text-left"
                >
                  <Typography
                    variant="small"
                    className="text-[11px] font-bold uppercase text-blue-gray-400"
                  >
                    {el}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map(({ cuit, company, created }, key) => {
                const className = `py-3 px-5 ${
                  key === data.length - 1 ? '' : 'border-b border-blue-gray-50'
                }`;

                return (
                  <tr key={created}>
                    <td className={className}>
                      <div className="flex items-center gap-4">
                        {/* <Avatar src={img} alt={name} size="sm" /> */}
                        <div>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-semibold"
                          >
                            {company.name}
                          </Typography>
                          <Typography className="text-xs font-normal text-blue-gray-500">
                            {company.file}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={className}>
                      <Chip
                        variant="gradient"
                        color={cuit ? 'gray' : 'blue-gray'}
                        value={cuit ? cuit : 'No disponible'}
                        className="py-0.5 px-2 text-[11px] font-medium"
                      />
                    </td>
                    <td className={className}>
                      <Typography className="text-xs font-semibold text-blue-gray-600">
                        {formatRelative(parseISO(created), new Date(), {
                          locale: es,
                        })}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </CardBody>
    </Card>
  );
};

export default QueriesTable;
