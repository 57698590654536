import { connect } from "react-redux";
import LoginPage from "../components/LoginPage";
import { userLogin } from "../actions/users";

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (email, password) => dispatch(userLogin(email, password)),
  };
};

const mapStatetoProps = (state) => {
  return {
    users: state.users,
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(LoginPage);
