module.exports = {
  chart: {
    zoomType: "x",
    type: "column",
  },
  title: {
    text: "Cheques Rechazados",
  },
  subtitle: {
    text: "Haga clic y arrastre en el área de trazado para acercar",
  },
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "Montos",
    },
  },
  legend: {
    enabled: true,
  },
};
