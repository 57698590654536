import React from 'react';
import SideBar from './Sidebar';
import TableRow from './TableRowCompany';
import { RotateSpinner } from 'react-spinners-kit';
import { Button, Input } from '@material-tailwind/react';
import Footer from './Footer';

export default class SearchCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: {
        search: null,
      },
    };
  }
  componentDidMount() {}

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { search } = this.state;

    const token = localStorage.getItem('token');
    if (!search) {
      this.setState({ validation: { search: true } });
      return;
    }
    if (token) {
      let er = /^-?[0-9]+$/;
      let query = {
        name: search,
      };
      if (er.test(search)) {
        query = { cuit: search };
      }
      this.props.searchCompany(token, query);
      this.setState({ validation: { search: false } });
    }
  };

  render() {
    return (
      <>
        <SideBar />
        <div className="md:ml-64 min-h-screen">
          <div className="flex flex-wrap">
            <div className="flex flex-1 justify-center md:justify-start text-white px-2 mt-5">
              <form onSubmit={this.handleSubmit} className="flex flex-1">
                <span className="relative lg:w-1/4 sm:w-auto">
                  <Input
                    name="search"
                    onChange={this.handleChange}
                    label="Nombre o Razón Social"
                    // error={charts.errorMessage ? true : false}
                  />
                </span>
                <Button type="submit" className="ml-4">
                  Buscar
                </Button>
              </form>
            </div>
          </div>
          {this.state.validation.search && (
            <p className="text-sm text-red-600 p-2">
              Debes escribir un Nombre o número de CUIT
            </p>
          )}
          {this.props.charts.searchCompanyLoading ? (
            <div className="m-auto w-32 mt-6">
              <RotateSpinner
                size={100}
                color="#686769"
                loading={this.props.charts.searchCompanyLoading}
              />
            </div>
          ) : (
            <table className="table-auto mx-2 mt-4 text-black bg-white">
              {this.props.charts.searchCompany && (
                <thead>
                  <tr>
                    <th className="px-4 py-2">Nombre</th>
                    <th className="px-4 py-2">CUIT</th>
                    <th className="px-4 py-2">Alta en el Padrón</th>
                    <th className="px-4 py-2">Alta en el Sitema</th>
                  </tr>
                </thead>
              )}

              <tbody>
                {this.props.charts.searchCompany
                  ? this.props.charts.searchCompany.map((item) => (
                      <TableRow key={item._id} {...item} />
                    ))
                  : null}
              </tbody>
            </table>
          )}
          <Footer sticky={true} />
        </div>
      </>
    );
  }
}
