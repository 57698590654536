import {
  CHART_SEARCH_REQUEST,
  CHART_SEARCH_SUCCESS,
  CHART_SEARCH_FAILURE,
  TOTAL_TRANSACTIONS_FAILURE,
  TOTAL_TRANSACTIONS_SUCCESS,
  TOTAL_TRANSACTIONS_REQUEST,
  TOTAL_COMPANIES_REQUEST,
  TOTAL_COMPANIES_SUCCESS,
  TOTAL_COMPANIES_FAILURE,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_REQUEST,
  GET_TRANSACTIONS_FAILURE,
  SEARCH_COMPANIES_SUCCESS,
  SEARCH_COMPANIES_REQUEST,
  SEARCH_COMPANIES_FAILURE,
  SEARCH_TRANSACTION_REQUEST,
  SEARCH_TRANSACTION_SUCCESS,
  SEARCH_TRANSACTION_FAILURE,
  SEARCH_COMPANY_REQUEST,
  SEARCH_COMPANY_SUCCESS,
  SEARCH_COMPANY_FAILURE,
} from "../actions/charts";

export const initialState = {
  data: null,
  isFetching: false,
  errorMessage: null,
  status: null,
  transactions: null,
  totalTransactions: null,
  totalCompanies: null,
  companies: [],
  companiesLoading: false,
  searchTransaction: null,
  searchTransactionLoading: false,
  searchCompany: null,
  searchCompanyLoading: false,
};

const chartsReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case CHART_SEARCH_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: null,
        errorMessage: null,
      };
    case CHART_SEARCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        errorMessage: null,
      };
    case CHART_SEARCH_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        errorMessage: error,
      };
    case TOTAL_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        totalTransactions: null,
        errorMessage: null,
      };
    case TOTAL_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        totalTransactions: action.payload,
        errorMessage: null,
      };
    case TOTAL_TRANSACTIONS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
        totalTransactions: action.payload,
      };
    case TOTAL_COMPANIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        totalCompanies: null,
        errorMessage: null,
      };
    case TOTAL_COMPANIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        totalCompanies: action.payload,
        errorMessage: null,
      };
    case TOTAL_COMPANIES_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
        totalCompanies: action.payload,
      };
    case GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
        transactions: null,
        errorMessage: null,
      };
    case GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        transactions: action.payload,
        errorMessage: null,
      };
    case GET_TRANSACTIONS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        isFetching: false,
        errorMessage: error,
        transactions: action.payload,
      };
    case SEARCH_COMPANIES_REQUEST:
      return {
        ...state,
        companiesLoading: true,
        companies: null,
        errorMessage: null,
      };
    case SEARCH_COMPANIES_SUCCESS:
      return {
        ...state,
        companiesLoading: false,
        companies: action.payload,
        errorMessage: null,
      };
    case SEARCH_COMPANIES_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        companiesLoading: false,
        errorMessage: error,
        companies: action.payload.data,
      };
    case SEARCH_TRANSACTION_REQUEST:
      return {
        ...state,
        searchTransactionLoading: true,
        searchTransaction: null,
        errorMessage: null,
      };
    case SEARCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        searchTransactionLoading: false,
        searchTransaction: action.payload,
        errorMessage: null,
      };
    case SEARCH_TRANSACTION_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        searchTransactionLoading: false,
        errorMessage: error,
        searchTransaction: null,
      };

    case SEARCH_COMPANY_REQUEST:
      return {
        ...state,
        searchCompanyLoading: true,
        searchCompany: null,
        errorMessage: null,
      };
    case SEARCH_COMPANY_SUCCESS:
      return {
        ...state,
        searchCompanyLoading: false,
        searchCompany: action.payload,
        errorMessage: null,
      };
    case SEARCH_COMPANY_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        searchCompanyLoading: false,
        errorMessage: error,
        searchCompany: action.payload.data,
      };
    default:
      return state;
  }
};

export { chartsReducer as default };
