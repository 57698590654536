import { connect } from 'react-redux';
import {
  totalTransactions,
  totalCompanies,
  getTransactions,
  searchCompanies,
} from '../actions/charts';
import { getLogsRequest } from '../actions/logs';
import Dashboard from '../components/admin/Dashboard';

const mapDispatchToProps = (dispatch) => {
  return {
    totalTransactions: (token) => dispatch(totalTransactions(token)),
    totalCompanies: (token) => dispatch(totalCompanies(token)),
    getTransactions: (token, query) => dispatch(getTransactions(token, query)),
    searchCompanies: (token, query) => dispatch(searchCompanies(token, query)),
    getLogsRequest: (token, query) => dispatch(getLogsRequest(token, query)),
  };
};

const mapStateToProps = (state) => {
  return {
    charts: state.charts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
