import { useLocation } from 'react-router-dom';
import { Button } from '@material-tailwind/react';
import { IconButton, Avatar } from '@material-tailwind/react';
import { Input } from '@material-tailwind/react';
import ProfilePicture from '../../assets/img/team-1-800x800.jpg';
import { faMagnifyingGlass, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
  const location = useLocation().pathname;

  return (
    <nav className="bg-light-blue-500 md:ml-64 py-6 px-3">
      <div className="container max-w-full mx-auto flex items-center justify-between md:pr-8 md:pl-10">
        <div className="md:hidden">
          <IconButton size="lg" onClick={() => setShowSidebar('left-0')}>
            <FontAwesomeIcon icon={faBars} />
          </IconButton>
          <div
            className={`absolute top-2 md:hidden ${
              showSidebar === 'left-0' ? 'left-64' : '-left-64'
            } z-50 transition-all duration-300`}
          >
            <IconButton size="lg" onClick={() => setShowSidebar('-left-64')}>
              <FontAwesomeIcon icon={faBars} />
            </IconButton>
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <h4 className="uppercase text-white text-sm tracking-wider mt-1">
            {location === '/admin'
              ? 'DASHBOARD'
              : location.toUpperCase().replace('/', '')}
          </h4>
          <div className="flex">
            <Input
              color="indigo"
              label="Search"
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            />

            <div className="-mr-4 ml-6">
              <Menu>
                <MenuHandler>
                  <div className="w-12">
                    <img
                      src={ProfilePicture}
                      className="w-10 h-10 rounded-full"
                    />
                  </div>
                </MenuHandler>
                <MenuList>
                  <MenuItem>Perfil</MenuItem>
                  <MenuItem>Configuraciones</MenuItem>
                  <MenuItem>Salir</MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
