export const GET_ALARMS_REQUEST = "GET_ALARMS_REQUEST";
export const GET_ALARMS_SUCCESS = "GET_ALARMS_SUCCESS";
export const GET_ALARMS_FAILURE = "GET_ALARMS_FAILURE";

export const CREATE_ALARMS_REQUEST = "CREATE_ALARMS_REQUEST";
export const CREATE_ALARMS_SUCCESS = "CREATE_ALARMS_SUCCESS";
export const CREATE_ALARMS_FAILURE = "CREATE_ALARMS_FAILURE";

export const getAlarmsRequest = (token) => {
  return { type: GET_ALARMS_REQUEST, payload: token };
};

export const getAlarmsSuccess = (alarms) => {
  return {
    type: GET_ALARMS_SUCCESS,
    payload: alarms,
  };
};

export const getAlarmsFailure = (error) => {
  return {
    type: GET_ALARMS_FAILURE,
    payload: error,
  };
};

export const createAlarmsRequest = (token, query) => {
  return { type: CREATE_ALARMS_REQUEST, payload: { token, query } };
};

export const createAlarmsSuccess = (alarm) => {
  return {
    type: CREATE_ALARMS_SUCCESS,
    payload: alarm,
  };
};

export const createAlarmsFailure = (error) => {
  return {
    type: CREATE_ALARMS_FAILURE,
    payload: error,
  };
};
