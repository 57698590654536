import axios from 'axios';
const apiUrl = process.env.REACT_APP_ES_URL || 'http://127.0.0.1:3029/search';

// axios.interceptors.request.use((config) => {
//   if (localStorage.getItem("token")) {
//     config.headers.Authorization = localStorage.getItem("token");
//   }
//   return config;
// });

export const search = (token, query) => {
  const options = {
    url: '/statistics/rejected',
    method: 'post',
    baseURL: apiUrl,
    data: query,
    headers: {
      'x-auth': token,
    },
  };

  return axios(options)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getTotalTransactions = (token) => {
  return axios
    .get(apiUrl + '/rejectedchecks/_count', {
      headers: {
        'x-auth': token,
      },
    })
    .then((resp) => {
      return resp.data.count;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getTotalCompanies = (token) => {
  return axios
    .get(apiUrl + '/companies/_count', {
      headers: {
        'x-auth': token,
      },
    })
    .then((resp) => {
      return resp.data.count;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getTransactions = ({ token, query }) => {
  const params = new URLSearchParams(query);
  return axios
    .get(apiUrl + '/rejectedchecks?' + params.toString(), {
      headers: {
        'x-auth': token,
      },
    })
    .then((resp) => {
      let doc = resp.data.checks.docs[0].created;
      return doc;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const searchCompanies = ({ token, query }) => {
  const options = {
    url: '/companies/search?select=_id',
    method: 'post',
    baseURL: apiUrl,
    data: query,
    headers: {
      'x-auth': token,
    },
  };

  return axios(options)
    .then((resp) => {
      return resp.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const searchByTransaction = (token, query) => {
  const options = {
    url: '/rejectedchecks/search',
    method: 'post',
    baseURL: apiUrl,
    data: query,
    headers: {
      'x-auth': token,
    },
  };

  return axios(options).then((resp) => {
    return resp.data;
  });
};

export const getAlarms = ({ token, query }) => {
  const params = new URLSearchParams(query);
  return axios
    .get(apiUrl + '/alarms?' + params.toString(), {
      headers: {
        'x-auth': token,
      },
    })
    .then((resp) => {
      return resp.data.alarms.docs;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getLogs = ({ token, query }) => {
  const params = new URLSearchParams(query);
  return axios
    .get(apiUrl + '/logs?' + params.toString(), {
      headers: {
        'x-auth': token,
      },
    })
    .then((resp) => {
      return resp.data.logs.docs;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const createAlarm = ({ token, query }) => {
  const options = {
    url: '/alarms',
    method: 'post',
    baseURL: apiUrl,
    data: query,
    headers: {
      'x-auth': token,
    },
  };
  return axios(options).then((resp) => {
    return resp.data;
  });
};

export const createQuery = ({ token, query }) => {
  console.log('QUERY: ', query);
  console.log('token: ', token);
  const options = {
    url: '/queries',
    method: 'post',
    baseURL: apiUrl,
    data: query,
    headers: {
      'x-auth': token,
    },
  };
  return axios(options).then((resp) => {
    return resp.data;
  });
};

export const getQueries = ({ token, query }) => {
  const params = new URLSearchParams(query);
  return axios
    .get(apiUrl + '/queries?' + params.toString(), {
      headers: {
        'x-auth': token,
      },
    })
    .then((resp) => {
      return resp.data.queries.docs;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const searchCompany = ({ token, query }) => {
  const options = {
    url: '/companies/search',
    method: 'post',
    baseURL: apiUrl,
    data: query,
    headers: {
      'x-auth': token,
    },
  };

  return axios(options).then((resp) => {
    return resp.data;
  });
};

export const login = ({ email, password }) => {
  const response = axios.post(apiUrl + '/users/login', {
    email,
    password,
  });
  return response;
};

export const signUp = ({ email, password }) => {
  const response = axios.post(apiUrl + '/users', {
    email,
    password,
  });
  return response;
};

export const loginFromToken = (token) => {
  const response = axios.get(apiUrl + '/users/me', {
    headers: { 'x-auth': token },
  });
  return response;
};
