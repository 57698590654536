import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePageContainer from '../containers/HomePageContainer';
import LoginPageContainer from '../containers/LoginPageContainer';
import SignUpPageContainer from '../containers/SignUpPageContainer';
import PrivateRouter from '../routers/PrivateRouter';
import history from './../history';
import DashboardContainer from '../containers/DashboardContainer';
import SearchTransactionsContainer from '../containers/SearchTransactionsContainer';
import SearchCompanyContainer from '../containers/SearchCompanyContainer';
import AlarmsContainer from '../containers/AlarmsContainer';

const AppRouter = () => {
  return (
    <Router history={history}>
      <Routes>
        <Route path="/" element={<HomePageContainer />} />
        <Route path="/login" element={<LoginPageContainer />} />
        <Route path="/signup" element={<SignUpPageContainer />} />

        <Route path="/admin" element={<PrivateRouter />}>
          <Route path="/admin" element={<DashboardContainer />} />
        </Route>
        <Route
          path="/admin/searchTransactions/:cuit"
          element={<PrivateRouter />}
        >
          <Route
            path="/admin/searchTransactions/:cuit"
            element={<SearchTransactionsContainer />}
          />
        </Route>
        <Route path="/admin/searchTransactions" element={<PrivateRouter />}>
          <Route
            path="/admin/searchTransactions"
            element={<SearchTransactionsContainer />}
          />
        </Route>
        <Route path="/admin/searchCompany" element={<PrivateRouter />}>
          <Route
            path="/admin/searchCompany"
            element={<SearchCompanyContainer />}
          />
        </Route>
        <Route path="/admin/alarms" element={<PrivateRouter />}>
          <Route path="/admin/alarms" element={<AlarmsContainer />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
