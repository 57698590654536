import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import chartsReducer from './../reducers/charts';
import usersReducer from './../reducers/users';
import alarmsReduder from './../reducers/alarms';
import sagas from '../sagas/sagas';
import AlarmsSagas from '../sagas/alarmsSagas';
import LogsSagas from '../sagas/logsSagas';
import logsReducer from '../reducers/logs';
import queriesReducer from '../reducers/queries';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  typeof window === 'object' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
    : compose;
const enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

const reducers = combineReducers({
  charts: chartsReducer,
  users: usersReducer,
  alarms: alarmsReduder,
  logs: logsReducer,
  queries: queriesReducer,
});

const store = createStore(reducers, enhancer);

function* rootSaga() {
  yield all([fork(sagas), fork(AlarmsSagas), fork(LogsSagas)]);
}

sagaMiddleware.run(rootSaga);

export default store;
