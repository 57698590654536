export const GET_QUERIES_REQUEST = "GET_QUERIES_REQUEST";
export const GET_QUERIES_SUCCESS = "GET_QUERIES_SUCCESS";
export const GET_QUERIES_FAILURE = "GET_QUERIES_FAILURE";

export const CREATE_QUERIES_REQUEST = "CREATE_QUERIES_REQUEST";
export const CREATE_QUERIES_SUCCESS = "CREATE_QUERIES_SUCCESS";
export const CREATE_QUERIES_FAILURE = "CREATE_QUERIES_FAILURE";

export const getQueriesRequest = (token) => {
  return { type: GET_QUERIES_REQUEST, payload: token };
};

export const getQueriesSuccess = (queries) => {
  return {
    type: GET_QUERIES_SUCCESS,
    payload: queries,
  };
};

export const getQueriesFailure = (error) => {
  return {
    type: GET_QUERIES_FAILURE,
    payload: error,
  };
};

export const createQueriesRequest = (token, query) => {
  return { type: CREATE_QUERIES_REQUEST, payload: { token, query } };
};

export const createQueriesSuccess = (alarm) => {
  return {
    type: CREATE_QUERIES_SUCCESS,
    payload: alarm,
  };
};

export const createQueriesFailure = (error) => {
  return {
    type: CREATE_QUERIES_FAILURE,
    payload: error,
  };
};
