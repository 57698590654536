import {
  GET_QUERIES_REQUEST,
  GET_QUERIES_SUCCESS,
  GET_QUERIES_FAILURE,
  CREATE_QUERIES_FAILURE,
  CREATE_QUERIES_REQUEST,
  CREATE_QUERIES_SUCCESS,
} from '../actions/queries';

export const initialState = {
  data: [],
  isFetching: false,
  errorMessage: null,
  status: null,
};

const queriesReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case GET_QUERIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: [],
        errorMessage: null,
      };
    case GET_QUERIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        errorMessage: null,
      };
    case GET_QUERIES_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        errorMessage: error,
        isFetching: false,
      };
    case CREATE_QUERIES_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: [],
        errorMessage: null,
      };
    case CREATE_QUERIES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [...state.data, action.payload],
        errorMessage: null,
      };
    case CREATE_QUERIES_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        errorMessage: error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default queriesReducer;
