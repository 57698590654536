import React, { useEffect, useReducer } from 'react';
import SideBar from './Sidebar';
import { GooSpinner } from 'react-spinners-kit';
import { format, parseISO, subDays } from 'date-fns';
import numeral from 'numeral';
import {
  getTotalTransactions,
  getTotalCompanies,
  searchCompanies,
  getLogs,
} from './../../api';
import chartsReducer from '../../reducers/charts';
import logsReducer from '../../reducers/logs';
import ChartRejected from './ChartRejected';
import QueriesBox from './QueriesBox';
import StatisticsCard from './StatisticsCard';
import { Typography } from '@material-tailwind/react';
import cardsData from './../../data/statisticsCardsData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from './Footer';

const Dashboard = () => {
  const [charts, dispatch] = useReducer(chartsReducer, []);
  const [logs, logsDispatch] = useReducer(logsReducer, []);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchTotalTransactions = async () => {
        dispatch({ type: 'TOTAL_TRANSACTIONS_REQUEST' });
        const totalTransactions = await getTotalTransactions(token);
        dispatch({
          type: 'TOTAL_TRANSACTIONS_SUCCESS',
          payload: totalTransactions,
        });
      };
      const fetchTotalCompanies = async () => {
        dispatch({ type: 'TOTAL_COMPANIES_REQUEST' });
        const totalCompanies = await getTotalCompanies(token);
        dispatch({
          type: 'TOTAL_COMPANIES_SUCCESS',
          payload: totalCompanies,
        });
      };
      const fetchSearchCompanies = async () => {
        dispatch({ type: 'SEARCH_COMPANIES_REQUEST' });
        const searchCompaniesResult = await searchCompanies({
          token: token,
          query: {
            created: format(subDays(new Date(), 1), 'yyyy-MM-dd'),
          },
        });
        dispatch({
          type: 'SEARCH_COMPANIES_SUCCESS',
          payload: searchCompaniesResult,
        });
      };
      const fetchLogs = async () => {
        logsDispatch({ type: 'GET_LOGS_REQUEST' });
        const logs = await getLogs({
          token: token,
          query: {
            sortBy: 'created:desc',
          },
        });
        logsDispatch({
          type: 'GET_LOGS_SUCCESS',
          payload: logs,
        });
      };
      fetchLogs(token);
      fetchTotalTransactions(token);
      fetchTotalCompanies(token);
      fetchSearchCompanies(token);
    }
  }, []);

  return (
    <>
      <SideBar />
      <div className="md:ml-64 min-h-screen">
        <div className="bg-light-blue-500 px-3 md:px-8 h-40" />
        <div className="px-3 md:px-8 -mt-24">
          <div className="container mx-auto max-w-full">
            <div className="grid grid-cols-1 xl:grid-cols-5">
              <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                <ChartRejected />
              </div>
              <div className="xl:col-start-4 xl:col-end-6 px-4 mb-14">
                <QueriesBox amount={7} />
              </div>
            </div>
          </div>
        </div>
        <div className="mb-12 grid gap-y-10 gap-x-6 md:grid-cols-2 xl:grid-cols-4 md:px-8">
          <StatisticsCard
            key={cardsData.checks.title}
            title={cardsData.checks.title}
            color={cardsData.checks.color}
            value={
              charts.isFetching ? (
                <div className="w-16 m-auto">
                  <GooSpinner
                    size={45}
                    color="#686769"
                    loading={charts.isFetching}
                  />
                </div>
              ) : (
                <span>{numeral(charts.totalTransactions).format('0a')}</span>
              )
            }
            icon={
              <FontAwesomeIcon
                icon={cardsData.checks.icon}
                className="w-6 h-6 text-white"
              />
            }
            footer={
              <Typography className="font-normal text-blue-gray-600">
                <strong className={cardsData.checks.footer.color}>
                  {cardsData.checks.footer.value}
                </strong>
                &nbsp;{cardsData.checks.footer.label}
              </Typography>
            }
          />
          <StatisticsCard
            key={cardsData.companies.title}
            title={cardsData.companies.title}
            color={cardsData.companies.color}
            value={
              charts.isFetching ? (
                <div className="w-16 m-auto">
                  <GooSpinner size={45} color="#686769" loading={true} />
                </div>
              ) : (
                <span>{numeral(charts.totalCompanies).format('0a')}</span>
              )
            }
            icon={
              <FontAwesomeIcon
                icon={cardsData.companies.icon}
                className="w-6 h-6 text-white"
              />
            }
            footer={
              <Typography className="font-normal text-blue-gray-600">
                <strong className={cardsData.companies.footer.color}>
                  {cardsData.companies.footer.value}
                </strong>
                &nbsp;{cardsData.companies.footer.label}
              </Typography>
            }
          />
          <StatisticsCard
            key={cardsData.logs.title}
            title={cardsData.logs.title}
            color={cardsData.logs.color}
            value={
              logs.isFetching ? (
                <div className="w-16 m-auto">
                  <GooSpinner
                    size={45}
                    color="#686769"
                    loading={logs.isFetching}
                  />
                </div>
              ) : (
                <span>
                  {logs.data
                    ? format(parseISO(logs.data[0].created), 'dd/MM/yyyy')
                    : 'No hay datos'}
                </span>
              )
            }
            icon={
              <FontAwesomeIcon
                icon={cardsData.logs.icon}
                className="w-6 h-6 text-white"
              />
            }
            footer={
              <Typography className="font-normal text-blue-gray-600">
                <strong className={cardsData.logs.footer.color}>
                  {cardsData.logs.footer.value}
                </strong>
                &nbsp;{cardsData.logs.footer.label}
              </Typography>
            }
          />
          <StatisticsCard
            key={cardsData.users.title}
            title={cardsData.users.title}
            color={cardsData.users.color}
            value={3}
            icon={
              <FontAwesomeIcon
                icon={cardsData.users.icon}
                className="w-6 h-6 text-white"
              />
            }
            footer={
              <Typography className="font-normal text-blue-gray-600">
                <strong className={cardsData.users.footer.color}>
                  {cardsData.users.footer.value}
                </strong>
                &nbsp;{cardsData.users.footer.label}
              </Typography>
            }
          />
        </div>
        <Footer sticky={true} />
      </div>
    </>
  );
};

export { Dashboard as default };
