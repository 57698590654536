export const CHART_SEARCH_REQUEST = "CHART_SEARCH_REQUEST";
export const CHART_SEARCH_SUCCESS = "CHART_SEARCH_SUCCESS";
export const CHART_SEARCH_FAILURE = "CHART_SEARCH_FAILURE";

export const TOTAL_TRANSACTIONS_REQUEST = "TOTAL_TRANSACTIONS_REQUEST";
export const TOTAL_TRANSACTIONS_SUCCESS = "TOTAL_TRANSACTIONS_SUCCESS";
export const TOTAL_TRANSACTIONS_FAILURE = "TOTAL_TRANSACTIONS_FAILURE";

export const TOTAL_COMPANIES_REQUEST = "TOTAL_COMPANIES_REQUEST";
export const TOTAL_COMPANIES_SUCCESS = "TOTAL_COMPANIES_SUCCESS";
export const TOTAL_COMPANIES_FAILURE = "TOTAL_COMPANIES_FAILURE";

export const GET_TRANSACTIONS_REQUEST = "GET_TRANSACTIONS_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "GET_TRANSACTIONS_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "GET_TRANSACTIONS_FAILURE";

export const SEARCH_COMPANIES_REQUEST = "SEARCH_COMPANIES_REQUEST";
export const SEARCH_COMPANIES_SUCCESS = "SEARCH_COMPANIES_SUCCESS";
export const SEARCH_COMPANIES_FAILURE = "SEARCH_COMPANIES_FAILURE";

export const SEARCH_TRANSACTION_REQUEST = "SEARCH_TRANSACTION_REQUEST";
export const SEARCH_TRANSACTION_SUCCESS = "SEARCH_TRANSACTION_SUCCESS";
export const SEARCH_TRANSACTION_FAILURE = "SEARCH_TRANSACTION_FAILURE";

export const SEARCH_COMPANY_REQUEST = "SEARCH_COMPANY_REQUEST";
export const SEARCH_COMPANY_SUCCESS = "SEARCH_COMPANY_SUCCESS";
export const SEARCH_COMPANY_FAILURE = "SEARCH_COMPANY_FAILURE";

export const chartSearch = (query) => {
  return { type: CHART_SEARCH_REQUEST, payload: query };
};

export const chartSuccess = (data) => {
  return {
    type: CHART_SEARCH_SUCCESS,
    payload: data,
  };
};

export const chartFailure = (error) => {
  return {
    type: CHART_SEARCH_FAILURE,
    payload: error,
  };
};

export const totalTransactions = (token) => {
  return { type: TOTAL_TRANSACTIONS_REQUEST, payload: token };
};

export const totalTransactionsSuccess = (data) => {
  return {
    type: TOTAL_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};

export const totalTransactionsFailure = (error) => {
  return {
    type: TOTAL_TRANSACTIONS_FAILURE,
    payload: error,
  };
};

export const totalCompanies = (token) => {
  return { type: TOTAL_COMPANIES_REQUEST, payload: token };
};

export const totalCompaniesSuccess = (data) => {
  return {
    type: TOTAL_COMPANIES_SUCCESS,
    payload: data,
  };
};

export const totalCompaniesFailure = (error) => {
  return {
    type: TOTAL_COMPANIES_FAILURE,
    payload: error,
  };
};

export const getTransactions = (token, query) => {
  return { type: GET_TRANSACTIONS_REQUEST, payload: { token, query } };
};

export const getTransactionsSuccess = (data) => {
  return {
    type: GET_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};

export const getTransactionsFailure = (error) => {
  return {
    type: GET_TRANSACTIONS_FAILURE,
    payload: error,
  };
};

export const searchCompanies = (token, query) => {
  return { type: SEARCH_COMPANIES_REQUEST, payload: { token, query } };
};

export const searchCompaniesSuccess = (data) => {
  return {
    type: SEARCH_COMPANIES_SUCCESS,
    payload: data,
  };
};

export const searchCompaniesFailure = (error) => {
  return {
    type: SEARCH_COMPANIES_FAILURE,
    payload: error,
  };
};

export const searchByTransaction = (token, query) => {
  return { type: SEARCH_TRANSACTION_REQUEST, payload: { token, query } };
};

export const searchByTransactionSuccess = (data) => {
  return {
    type: SEARCH_TRANSACTION_SUCCESS,
    payload: data,
  };
};

export const searchByTransactionFailure = (error) => {
  return {
    type: SEARCH_TRANSACTION_FAILURE,
    payload: error,
  };
};

export const searchCompany = (token, query) => {
  return { type: SEARCH_COMPANY_REQUEST, payload: { token, query } };
};

export const searchCompanySuccess = (data) => {
  return {
    type: SEARCH_COMPANY_SUCCESS,
    payload: data,
  };
};

export const searchCompanyFailure = (error) => {
  return {
    type: SEARCH_COMPANY_FAILURE,
    payload: error,
  };
};
