import React from "react";
import { format } from "date-fns";

const TableRow = ({ name, cuit, user, created, modified }) => {
  return (
    <tr>
      <td className="border px-4 py-2">{name}</td>
      <td className="border px-4 py-2">{cuit}</td>
      <td className="border px-4 py-2">{user.email}</td>
      <td className="border px-4 py-2">
        {created ? format(new Date(created), "dd/MM/yyyy") : ""}
      </td>
      <td className="border px-4 py-2">
        {modified ? format(new Date(modified), "dd/MM/yyyy") : ""}
      </td>
    </tr>
  );
};
export default TableRow;
