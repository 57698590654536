import {
  faMoneyBill1,
  faChartBar,
  faArrowAltCircleUp,
  faCalendarTimes,
} from '@fortawesome/free-regular-svg-icons';

export const cardsData = {
  checks: {
    color: 'blue',
    icon: faMoneyBill1,
    title: 'Total de Cheques',
    footer: {
      color: 'text-green-500',
      value: '+55%',
      label: 'semana anterior',
    },
    value: 'totalTransactions',
  },
  companies: {
    color: 'pink',
    title: 'Personas o Empresas',
    value: '2,300',
    icon: faChartBar,
    footer: {
      color: 'text-green-500',
      value: '+3%',
      label: 'mes pasado',
    },
    value: 'totalCompanies',
  },
  users: {
    color: 'green',
    title: 'Nuevas Altas',
    value: '3,462',
    icon: faArrowAltCircleUp,
    footer: {
      color: 'text-red-500',
      value: '-2%',
      label: 'menos que ayer',
    },
    value: 'newUsers',
  },
  logs: {
    color: 'orange',
    title: 'Fecha de Registros',
    value: '$103,430',
    icon: faCalendarTimes,
    footer: {
      color: 'text-green-500',
      value: '+5%',
      label: 'semana anterior',
    },
    value: 'logs',
  },
};

export default cardsData;
