import { call, put, takeLatest } from "redux-saga/effects";
import {
  search,
  login,
  signUp,
  loginFromToken,
  getTotalTransactions,
  getTotalCompanies,
  getTransactions,
  searchCompanies,
  searchByTransaction,
  searchCompany,
} from "../api";

function* searchAll(action) {
  try {
    const request = yield call(search, action.payload);
    yield put({
      type: "CHART_SEARCH_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "CHART_SEARCH_FAILURE", payload: e.message });
  }
}

function* totalTransactionsSagas(action) {
  try {
    const request = yield call(getTotalTransactions, action.payload);
    yield put({
      type: "TOTAL_TRANSACTIONS_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "TOTAL_TRANSACTIONS_FAILURE", payload: e.message });
  }
}

function* totalCompaniesSagas(action) {
  try {
    const request = yield call(getTotalCompanies, action.payload);
    yield put({
      type: "TOTAL_COMPANIES_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "TOTAL_COMPANIES_FAILURE", payload: e.message });
  }
}

function* getTransactionsSagas(action) {
  try {
    const request = yield call(getTransactions, action.payload);
    yield put({
      type: "GET_TRANSACTIONS_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "GET_TRANSACTIONS_FAILURE", payload: e.message });
  }
}

function* searchCompaniesSagas(action) {
  try {
    const request = yield call(searchCompanies, action.payload);
    yield put({
      type: "SEARCH_COMPANIES_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "SEARCH_COMPANIES_FAILURE", payload: e.message });
  }
}

function* searchCompanySagas(action) {
  try {
    const request = yield call(searchCompany, action.payload);
    yield put({
      type: "SEARCH_COMPANY_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "SEARCH_COMPANY_FAILURE", payload: e.message });
  }
}

function* searchByTransactionSagas(action) {
  try {
    const request = yield call(searchByTransaction, action.payload);
    yield put({
      type: "SEARCH_TRANSACTION_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "SEARCH_TRANSACTION_FAILURE", payload: e.message });
  }
}

function* userLogin(action) {
  try {
    const request = yield call(login, action.payload);
    yield put({
      type: "LOGIN_USER_SUCCESS",
      payload: Object.assign(request.data, request.headers),
    });
    localStorage.setItem("token", request.headers["x-auth"]);
    localStorage.setItem("userId", request.data._id);
    window.location.href = "/admin";
  } catch (e) {
    yield put({ type: "LOGIN_USER_FAILURE", payload: e.message });
  }
}

function* userSignUpSagas(action) {
  try {
    const request = yield call(signUp, action.payload);
    yield put({
      type: "SIGNUP_USER_SUCCESS",
      payload: Object.assign(request.data, request.headers),
    });
    localStorage.setItem("token", request.headers["x-auth"]);
    localStorage.setItem("user", request.data);
    window.location.href = "/admin";
  } catch (e) {
    yield put({ type: "SIGNUP_USER_FAILURE", payload: e.message });
  }
}

function* userLoginFromToken(action) {
  try {
    const request = yield call(loginFromToken, action.payload);
    yield put({ type: "LOGIN_USER_FROM_TOKEN_SUCCESS", payload: request.data });
  } catch (e) {
    yield put({ type: "LOGIN_USER_FROM_TOKEN_FAILURE", payload: e.message });
  }
}

function* sagas() {
  yield takeLatest("LOGIN_USER_FROM_TOKEN_REQUEST", userLoginFromToken);
  yield takeLatest("LOGIN_USER_REQUEST", userLogin);
  yield takeLatest("CHART_SEARCH_REQUEST", searchAll);
  yield takeLatest("TOTAL_TRANSACTIONS_REQUEST", totalTransactionsSagas);
  yield takeLatest("TOTAL_COMPANIES_REQUEST", totalCompaniesSagas);
  yield takeLatest("GET_TRANSACTIONS_REQUEST", getTransactionsSagas);
  yield takeLatest("SEARCH_COMPANIES_REQUEST", searchCompaniesSagas);
  yield takeLatest("SIGNUP_USER_REQUEST", userSignUpSagas);
  yield takeLatest("SEARCH_TRANSACTION_REQUEST", searchByTransactionSagas);
  yield takeLatest("SEARCH_COMPANY_REQUEST", searchCompanySagas);
}
export default sagas;
