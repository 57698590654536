import React from 'react';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

const TableRow = ({ name, cuit, file, created }) => {
  return (
    <tr>
      <td className="border px-4 py-2">{name}</td>
      <td className="border px-4 py-2">{cuit}</td>
      <td className="border px-4 py-2">{file}</td>
      <td className="border px-4 py-2">
        {created ? format(new Date(created), 'dd/MM/yyyy') : ''}
      </td>
      <td className="border px-4 py-2">
        <Link to={`/admin/searchTransactions/${cuit}`}>
          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
            Chequear
          </button>
        </Link>
      </td>
    </tr>
  );
};
export default TableRow;
