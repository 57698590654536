import React, { useState } from 'react';
import { createAlarm } from '../../api';

const AlarmsForm = ({ dispatch }) => {
  const [data, setData] = useState({
    name: '',
    cuit: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');

    if (token && userId) {
      let query = {
        name: data.name,
        cuit: data.cuit,
        user: userId,
      };

      const addAlarm = async () => {
        const data = await createAlarm({ token, query });
        dispatch({ type: 'CREATE_ALARMS_SUCCESS', payload: data });
      };
      addAlarm();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  return (
    <form className="w-1/2 m-2" onSubmit={handleSubmit}>
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-name"
          >
            Nombre
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="grid-city"
            type="text"
            placeholder="Nombre"
            onChange={handleChange}
            name="name"
          />
        </div>
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-CUIT"
          >
            CUIT
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type="text"
            placeholder="23342574949"
            onChange={handleChange}
            name="cuit"
          />
        </div>
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0 pt-8">
          <button
            type="submit"
            className="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 ml-3 rounded"
          >
            Crear
          </button>
        </div>
      </div>
    </form>
  );
};

export { AlarmsForm as default };
