import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import chartOptions from './../../charts/transactions';
import { connect } from 'react-redux';
import _ from 'lodash';

class TransactionsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCharts = () => {
    if (this.props.data.checks != 0) {
      let data = this.props.data.checks.map((element) => {
        return {
          x: new Date(element.date_reject).getTime(),
          y: Math.trunc(parseInt(element.amount)),
        };
      });
      data = _.orderBy(data, ['x']);
      return data;
    }
    return;
  };

  render() {
    const data = this.props.data;
    return (
      data &&
      data.checks.length != 0 && (
        <div className="w-full m-auto mt-6">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              series: [
                {
                  data: this.handleCharts(),
                  name: 'Monto (ARS)',
                },
              ],
              ...chartOptions,
            }}
          />
        </div>
      )
    );
  }
}

const mapStateToprops = (state) => {
  return {
    charts: state.charts,
  };
};

export default connect(mapStateToprops, null)(TransactionsChart);
