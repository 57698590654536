import React from "react";
import PersonalFinanceIcon from "./Icons/PersonalFinanceIcon";
import InvestmentIcon from "./Icons/InvestmentIcon";

const FeatureSection = () => {
  return (
    <section class="bg-white border-b py-8">
      <div class="container max-w-5xl mx-auto m-8">
        <h1 class="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
          Qué ofrecemos?
        </h1>
        <div class="w-full mb-4">
          <div class="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
        </div>

        <div class="flex flex-wrap">
          <div class="w-5/6 sm:w-1/2 p-6">
            <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
              Verificación de Fondos
            </h3>
            <p class="text-gray-600 mb-8">
              Utilizamos distinas fuentas de datos para validar si un cheque
              puede ser rechazado por falta de fondos. Este es uno de los grandes problemas que afecta, sobre todo, a la cadena de pagos en las empresas en Argentina
              <br />
              <br />
            </p>
          </div>
          <div class="w-full sm:w-1/2 p-6">
            <PersonalFinanceIcon />
          </div>
        </div>

        <div class="flex flex-wrap flex-col-reverse sm:flex-row">
          <div class="w-full sm:w-1/2 p-6 mt-6">
            <InvestmentIcon />
          </div>
          <div class="w-full sm:w-1/2 p-6 mt-6">
            <div class="align-middle">
              <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">
                Reportes
              </h3>
              <p class="text-gray-600 mb-8">
                Procesamos grandes volúmenes de datos y presentamos informes para que poder tomar decisiones de forma adecuada y simple. Ofrecemos acceso a una variedad de herramientas BI.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
