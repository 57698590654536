export const GET_LOGS_REQUEST = 'GET_LOGS_REQUEST';
export const GET_LOGS_SUCCESS = 'GET_LOGS_SUCCESS';
export const GET_LOGS_FAILURE = 'GET_LOGS_FAILURE';

export const getLogsRequest = (token) => {
  return { type: GET_LOGS_REQUEST, payload: token };
};

export const getLogsSuccess = (logs) => {
  return {
    type: GET_LOGS_SUCCESS,
    payload: logs,
  };
};

export const getLogsFailure = (error) => {
  return {
    type: GET_LOGS_FAILURE,
    payload: error,
  };
};
