module.exports = {
  chart: {
    zoomType: "x",
    type: "area",
  },
  title: {
    text: "Cheques Rechazados según datos de BCRA",
  },
  subtitle: {
    text:
      document.ontouchstart === undefined
        ? "Click and drag in the plot area to zoom in"
        : "Pinch the chart to zoom in",
  },
  xAxis: {
    type: "datetime",
  },
  yAxis: {
    title: {
      text: "Cheques Rechazados",
    },
  },
  legend: {
    enabled: true,
  },
};
