import React from 'react';
import Nav from './Nav';
import Hero from './Hero';
import HeroBottom from './HeroBottom';
import FeatureSection from './FeatureSection';
import ChartSection from './ChartSection';
import Footer from './Footer';

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Nav />
        <Hero />
        <HeroBottom />
        <FeatureSection />
        <ChartSection />
        <Footer />
      </div>
    );
  }
}
