import { connect } from "react-redux";
import { getAlarmsRequest, createAlarmsRequest } from "../actions/alarms";
import Alarms from "../components/admin/Alarms";

const mapDispatchToProps = (dispatch) => {
  return {
    getAlarmsRequest: (token) => dispatch(getAlarmsRequest(token)),
    createAlarmsRequest: (token) => dispatch(createAlarmsRequest(token)),
  };
};

const mapStateToProps = (state) => {
  return {
    alarms: state.alarms,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alarms);
