import React from "react";
import { format } from "date-fns";

const TableRow = ({
  number,
  amount,
  reject_reason,
  date_reject,
  date_payment,
}) => {
  return (
    <tr>
      <td className="hidden md:block border px-4 py-2">{number}</td>
      <td className="border px-4 py-2">{amount}</td>
      <td className="border px-4 py-2">{reject_reason}</td>
      <td className="border px-4 py-2">
        {date_reject ? format(new Date(date_reject), "dd/MM/yyyy") : ""}
      </td>
      <td className="border px-4 py-2">
        {date_payment
          ? format(new Date(date_payment), "dd/MM/yyyy")
          : "Sin Pagar"}
      </td>
    </tr>
  );
};
export default TableRow;
