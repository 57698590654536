import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from '@material-tailwind/react';
import RejectedChecks from '../RejectedChecks';

export default function ChartRejected() {
  return (
    <Card className="px-4">
      <CardHeader
        variant="gradient"
        color="orange"
        className="mx-0 -mt-10 mb-4 grid items-center h-24 py-4 px-8 justify-start"
      >
        <Typography className="uppercase text-gray-200 text-xs font-medium">
          Últimos 6 meses
        </Typography>
        <Typography className="text-white text-2xl">
          Cheques Rechazados
        </Typography>
      </CardHeader>
      <CardBody className="text-center">
        <RejectedChecks />
      </CardBody>
    </Card>
  );
}
