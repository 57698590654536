import React, { useReducer, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import chartOptions from '../charts/rejectedChecks';
import { GooSpinner } from 'react-spinners-kit';
import chartsReducer from '../reducers/charts';
import { search } from './../api';

const RejetedChecks = () => {
  const [charts, dispatch] = useReducer(chartsReducer, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchChart = async () => {
        dispatch({ type: 'CHART_SEARCH_REQUEST' });
        const rejected = await search(token, { name: 'date_reject_all' });
        dispatch({
          type: 'CHART_SEARCH_SUCCESS',
          payload: rejected,
        });
      };
      fetchChart();
    }
  }, []);

  return (
    <div className="w-full m-auto mt-6">
      {charts.isFetching ? (
        <div className="w-64 m-auto">
          <GooSpinner size={250} color="#686769" loading={charts.isFetching} />
        </div>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            series: [
              {
                data: charts.data,
                name: 'Nro de cheques rechazados',
              },
            ],
            ...chartOptions,
          }}
        />
      )}
    </div>
  );
};

export default RejetedChecks;
