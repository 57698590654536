import React, { useState, useReducer, useEffect } from 'react';
import SideBar from './Sidebar';
import { RotateSpinner } from 'react-spinners-kit';
import TransactionsChart from './TransactionsChart';
import TransactionsTable from './TransactionsTable';
import chartsReducer from '../../reducers/charts';
import { searchByTransaction, createQuery } from '../../api';
import { Button, Alert, Input } from '@material-tailwind/react';
import { useParams } from 'react-router-dom';
import Footer from './Footer';

const SearchTransactions = () => {
  const [charts, dispatch] = useReducer(chartsReducer, []);
  const [state, setState] = useState();
  const [validation, setValidation] = useState({
    validation: { search: false },
  });
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  let { cuit } = useParams();

  useEffect(() => {
    if (cuit && token) {
      setState({ ['query']: cuit });
      searchTransaction(cuit);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ [name]: value });
  };

  const searchTransaction = (query) => {
    dispatch({ type: 'SEARCH_TRANSACTION_REQUEST' });
    const initFetch = async () => {
      const data = await searchByTransaction(token, { cuit: query });
      setValidation({ validation: { search: false } });
      dispatch({ type: 'SEARCH_TRANSACTION_SUCCESS', payload: data });
    };

    const addQuery = async () => {
      dispatch({ type: 'CREATE_QUERIES_REQUEST' });
      const data = await createQuery({
        token: token,
        query: { cuit: query, user: userId },
      });
      dispatch({ type: 'CREATE_QUERIES_SUCCESS', payload: data });
    };

    initFetch().catch((e) => {
      dispatch({ type: 'SEARCH_TRANSACTION_FAILURE', payload: e });
    });

    addQuery().catch((e) => {
      dispatch({ type: 'CREATE_QUERIES_FAILURE', payload: e });
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { query } = state;

    if (!query) {
      setValidation({ validation: { search: true } });
      return;
    }
    if (token) {
      searchTransaction(query);
    }
  };

  return (
    <>
      <SideBar />
      <div className="md:ml-64 min-h-screen">
        <div className="flex flex-wrap">
          <div className="flex flex-1 justify-center md:justify-start text-white px-2 mt-5">
            <form onSubmit={handleSubmit} className="flex flex-1">
              <span className="relative lg:w-1/4 sm:w-auto">
                <div className="flex w-full items-end gap-4">
                  <Input
                    name="query"
                    value={state ? state.query : ''}
                    onChange={handleChange}
                    label="Nro de CUIT"
                    error={charts.errorMessage ? true : false}
                  />
                </div>
              </span>
              <Button type="submit" className="ml-4">
                Buscar
              </Button>
            </form>
          </div>
        </div>
        {charts.errorMessage && (
          <Alert className="lg:w-1/4 sm:w-auto my-4" color="amber">
            No se encontraron resultados para el CUIT: {state.query}
          </Alert>
        )}
        {validation.search && (
          <p className="text-sm text-red-600 p-2">
            Debes escribir un número de CUIT
          </p>
        )}

        {charts.searchTransactionLoading ? (
          <div className="m-auto w-32 mt-6">
            <RotateSpinner
              size={100}
              color="#686769"
              loading={charts.searchTransactionLoading}
            />
          </div>
        ) : (
          <>
            <TransactionsChart data={charts.searchTransaction} />
            <TransactionsTable data={charts.searchTransaction} />
          </>
        )}
        <Footer sticky={true} />
      </div>
    </>
  );
};

export default SearchTransactions;
