import { connect } from "react-redux";
import { searchByTransaction } from "../actions/charts";
import SearchTransactions from "../components/admin/SearchTransactions";

const mapDispatchToProps = (dispatch) => {
  return {
    searchByTransaction: (token, query) =>
      dispatch(searchByTransaction(token, query)),
  };
};

const mapStateToProps = (state) => {
  return {
    charts: state.charts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTransactions);
