import {
  GET_LOGS_REQUEST,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAILURE,
} from '../actions/logs';

export const initialState = {
  data: [],
  isFetching: false,
  errorMessage: null,
  status: null,
};

const logsReducer = (state = initialState, action) => {
  let error;
  switch (action.type) {
    case GET_LOGS_REQUEST:
      return {
        ...state,
        isFetching: true,
        data: [],
        errorMessage: null,
      };
    case GET_LOGS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        errorMessage: null,
      };
    case GET_LOGS_FAILURE:
      error = action.payload || { message: action.payload.message };
      return {
        ...state,
        errorMessage: error,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default logsReducer;
