import React from "react";
import Nav from "./Nav";
import { MetroSpinner } from "react-spinners-kit";

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chartOptions: null,
      validation: {
        email: null,
        password: null,
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    if (!email) {
      this.setState({ validation: { email: true } });
    }

    if (!password) {
      this.setState({ validation: { password: true } });
    }

    if (email && password) {
      this.props.userLogin(email, password);
    }
  };

  render() {
    const { email, password } = this.state;
    return (
      <div>
        <Nav />
        <div className="w-full flex flex-col">
          <div className="flex flex-col items-center my-auto pt-48 pb-48 px-8">
            <div className="w-full max-w-xs">
              <form
                onSubmit={this.handleSubmit}
                className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
              >
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="username"
                  >
                    Username
                  </label>
                  <input
                    className={`shadow appearance-none border ${
                      this.state.validation.email && "border-red-500"
                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                    id="username"
                    type="text"
                    placeholder="Username"
                    value={email || ""}
                    name="email"
                    onChange={this.handleChange}
                  />
                  {this.state.validation.email && (
                    <p className="text-red-500 text-xs italic">
                      Please choose a username.
                    </p>
                  )}
                </div>
                <div className="mb-6">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    className={`shadow appearance-none border ${
                      this.state.validation.password && "border-red-500"
                    } rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline`}
                    id="password"
                    type="password"
                    placeholder="******************"
                    name="password"
                    value={password || ""}
                    onChange={this.handleChange}
                  />
                  {this.state.validation.password && (
                    <p className="text-red-500 text-xs italic">
                      Please choose a password.
                    </p>
                  )}

                  {this.props.users.errorMessage && (
                    <p className="text-red-500 text-xs italic">
                      {this.props.users.errorMessage}
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <button
                    className="bg-black hover:bg-gray-200 hover:text-black text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    type="submit"
                  >
                    {this.props.users.isFetching ? (
                      <MetroSpinner size={25} />
                    ) : (
                      "Sign In "
                    )}
                  </button>
                  <a
                    className="inline-block align-baseline font-bold text-sm text-black hover:text-gray-500"
                    href="#"
                  >
                    Forgot Password?
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
