import { call, put, takeLatest } from "redux-saga/effects";
import { getAlarms, createAlarm } from "../api";

function* getAlarmsSagas(action) {
  try {
    const request = yield call(getAlarms, action.payload);
    yield put({
      type: "GET_ALARMS_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "GET_ALARMS_FAILURE", payload: e.message });
  }
}

function* createAlarmsSagas(action) {
  try {
    const request = yield call(createAlarm, action.payload);
    yield put({
      type: "CREATE_ALARMS_SUCCESS",
      payload: request,
    });
  } catch (e) {
    yield put({ type: "CREATE_ALARMS_FAILURE", payload: e.message });
  }
}

function* AlarmsSagas() {
  yield takeLatest("GET_ALARMS_REQUEST", getAlarmsSagas);
  yield takeLatest("CREATE_ALARMS_REQUEST", createAlarmsSagas);
}
export default AlarmsSagas;
