import { connect } from "react-redux";
import { chartSearch } from "../actions/charts";
import HomePage from "../components/HomePage";

const mapDispatchToProps = (dispatch) => {
  return {
    chartSearch: (query) => dispatch(chartSearch(query)),
  };
};

export default connect(null, mapDispatchToProps)(HomePage);
