import React from "react";
import TableRow from "./TableRowAlarms";
const AlarmsTable = ({ loading, data }) => {
  return (
    loading && (
      <table className="table-auto mx-2 mt-4 text-black bg-white">
        <thead>
          <tr>
            <th className="hidden md:block px-4 py-2">Nombre</th>
            <th className="px-4 py-2">CUIT</th>
            <th className="px-4 py-2">Usuario</th>
            <th className="px-4 py-2">Creado</th>
            <th className="px-4 py-2">Modificado</th>
          </tr>
        </thead>
        <tbody>
          {console.log("DATAAAAA : ", data)}
          {data
            ? data.map((item) => <TableRow key={item._id} {...item} />)
            : null}
        </tbody>
      </table>
    )
  );
};

export default AlarmsTable;
