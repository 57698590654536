import React from "react";

const InvestmentIcon = () => {
  return (
    <svg
      className="w-5/6 sm:h-64 mx-auto"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
      viewBox="0 0 1051.00009 810.19183"
    >
      <title>investment</title>
      <path
        d="M85.74923,373.9271c28.932-187.83127,128.90121-323.41919,246.24343-328.8575C394.028,42.19453,459.30643,76.16306,506.834,195.03135c84.9606,212.48992-5.221,359.327-68.09328,429.101-30.40161,33.73895-57.71038,74.41906-81.31574,120.66651C314.9568,828.0022,233.25775,931.394,135.95788,769.39494,65.4348,651.97781,66.47765,499.04129,85.74923,373.9271Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <rect
        x="205.35007"
        y="495.51999"
        width="845.65002"
        height="307.43005"
        fill="#3f3d56"
      />
      <polygon
        points="433.47 495.52 205.35 652.12 205.35 561.43 301.36 495.52 433.47 495.52"
        opacity="0.1"
      />
      <polygon
        points="658.91 495.52 445.54 642 437.9 647.25 211.09 802.95 205.35 802.95 205.35 716.19 351.5 615.87 356.39 612.51 526.81 495.52 658.91 495.52"
        opacity="0.1"
      />
      <path
        d="M304.70628,695.10921l-2.27616-2.85254c.22628-.17995,23.03639-18.16916,59.47047-28.45863a188.15907,188.15907,0,0,1,64.9907-6.72423c27.14817,1.975,54.14177,9.98746,80.23071,23.81544l-1.70868,3.22493c-58.04108-30.76507-109.53966-26.11922-142.5212-16.80436C327.15915,677.40152,304.92676,694.93282,304.70628,695.10921Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <path
        d="M525.24349,693.68294c-10.89133-1.88178-25.30391-2.66919-35.68584-.66525l14.14621-11.851,3.28993-18.1553C510.39338,673.02349,518.21391,685.15532,525.24349,693.68294Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <path
        d="M678.00338,730.78027a189.387,189.387,0,0,1-51.552-7.21243c-36.43408-10.28947-59.24419-28.27867-59.47047-28.45863l2.27571-2.85254c.22138.17639,22.56423,17.77,58.33551,27.84126,32.95036,9.27834,84.39594,13.88588,142.37154-16.84623l1.70867,3.22493c-26.08893,13.828-53.08209,21.8404-80.23025,23.81545Q684.73118,730.78115,678.00338,730.78027Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <path
        d="M789.79425,693.68294c-7.02779,8.53069-14.84829,20.66252-18.24788,30.67471l-3.29174-18.15841-14.14441-11.84793C764.49212,696.3552,778.90473,695.56784,789.79425,693.68294Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <path
        d="M843.84293,695.10921l-2.27705-2.85254c.22628-.17995,23.03684-18.16916,59.47136-28.45863a188.21906,188.21906,0,0,1,64.99115-6.72423c27.14817,1.975,54.14133,9.98746,80.23026,23.81544l-1.70868,3.22493c-58.03974-30.76418-109.53966-26.12011-142.5212-16.80436C866.29536,677.40152,844.063,694.93282,843.84293,695.10921Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <path
        d="M1064.3797,693.68294c-10.89136-1.88178-25.304-2.66919-35.68587-.66525l14.14624-11.851,3.29-18.1553C1049.52956,673.02349,1057.35012,685.15532,1064.3797,693.68294Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <rect
        x="702.52288"
        y="579.44831"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="702.52288"
        y="597.69319"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="702.52288"
        y="615.93807"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="436.14763"
        y="687.09311"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="436.14763"
        y="705.33799"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="436.14763"
        y="723.58287"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="977.10833"
        y="687.09311"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="977.10833"
        y="705.33799"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <rect
        x="977.10833"
        y="723.58287"
        width="59.29586"
        height="1.82449"
        fill="#ff6347"
      />
      <circle cx="467.16393" cy="659.72579" r="11.85917" fill="#ff6347" />
      <circle cx="732.62694" cy="643.30539" r="11.85917" fill="#ff6347" />
      <circle cx="1006.30014" cy="659.72579" r="11.85917" fill="#ff6347" />
      <path
        d="M422.58125,457.87186,404.91736,438.934l18.93783-17.66388L441.51907,440.208ZM407.73288,439.032l14.94636,16.02431L438.70356,440.11l-14.94637-16.02431Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <path
        d="M847.58125,235.87186,829.91736,216.934l18.93783-17.66388L866.51907,218.208ZM832.73288,217.032l14.94636,16.02431L863.70356,218.11l-14.94637-16.02431Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <path
        d="M1064.50005,541.96036h-25.897v-25.897h25.897Zm-23.90492-1.99208H1062.508V518.05545h-21.91284Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <circle cx="606.44991" cy="585.84332" r="13.2214" fill="#ff6347" />
      <path
        d="M680.94986,668.94256A38.19516,38.19516,0,1,1,719.145,630.74741,38.238,38.238,0,0,1,680.94986,668.94256Zm0-73.45222a35.25707,35.25707,0,1,0,35.25707,35.25707A35.29746,35.29746,0,0,0,680.94986,595.49034Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#f2f2f2"
      />
      <rect
        x="532.06954"
        y="267.40461"
        width="110.15278"
        height="128.33333"
        fill="#f2f2f2"
      />
      <path
        d="M679.27135,632.4449l-1.53054-1.494a78.18365,78.18365,0,0,0,15.68031-27.43219c5.20362-15.65995,7.74564-40.3919-9.69758-69.282-17.84688-29.55907-18.65-53.56778-16.18162-68.50345,2.68667-16.25159,9.54041-25.36119,9.83075-25.74082l1.69972,1.29869c-.0684.08929-6.86261,9.15032-9.44066,24.91732-2.38014,14.55656-1.54986,37.98355,15.9226,66.92226,17.88918,29.629,15.23907,55.04767,9.86626,71.15357C689.59869,621.73479,679.69067,632.01566,679.27135,632.4449Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <path
        d="M759.50005,441.71147h-154v-154h154Zm-151.86111-2.13889H757.36116V289.85036H607.63894Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <rect
        x="563.08343"
        y="287.72405"
        width="94.11111"
        height="2.13889"
        fill="#3f3d56"
      />
      <rect
        x="563.08343"
        y="311.25183"
        width="94.11111"
        height="2.13889"
        fill="#3f3d56"
      />
      <rect
        x="563.08343"
        y="334.77961"
        width="94.11111"
        height="2.13889"
        fill="#3f3d56"
      />
      <polygon
        points="155.075 708.867 151.207 746.266 173.13 743.687 175.709 712.736 155.075 708.867"
        fill="#ffb9b9"
      />
      <path
        d="M171.54244,470.05473s-112.197,108.32818-41.26788,141.85833h21.92356s47.716-78.6669,70.92916-77.37727l6.44811,74.798-3.86886,150.88568,28.37166,1.28962s16.76508-76.08765,15.47546-118.64515l29.66128-157.33378Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#2f2e41"
      />
      <path
        d="M136.72266,606.75457l15.47546,5.15849s60.61219-14.18584,64.48106-12.89622l11.99967-.08288-.39308-30.868H176.70092Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#2f2e41"
      />
      <path
        d="M250.20933,783.43267s-23.21318-10.317-27.082,0-2.57924,39.97826-2.57924,39.97826,5.15849,9.02735,2.57924,19.34432,10.317,15.47545,27.082,10.317,16.76507-18.0547,16.76507-18.0547Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#2f2e41"
      />
      <path
        d="M269.55365,565.48669s18.05469-10.317,16.76507,5.15849-7.73773,29.66129-7.73773,29.66129,16.76508,43.84712,0,47.716-29.66128-5.15849-29.66128-11.60659S269.55365,565.48669,269.55365,565.48669Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#2f2e41"
      />
      <polygon
        points="138.31 167.226 140.89 216.232 162.813 235.576 187.316 200.757 187.316 165.937 138.31 167.226"
        fill="#ffb9b9"
      />
      <path
        d="M229.57539,261.13609,214.81218,250.166l-9.73959,14.839-43.84712,25.79242,25.79242,67.0603,1.28962,65.77068s-47.716,49.00561-19.34432,55.45371S297.92531,499.716,303.0838,488.10942s-6.44811-131.54136-6.44811-131.54136L300.50456,254.688l-38.68864-13.98782Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#d0cde1"
      />
      <path
        d="M150.9085,342.81379s-10.317,73.07685,9.02735,73.07685a88.8289,88.8289,0,0,0,33.53015-6.44811l-15.47546-39.97826,6.44811-27.082Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#ffb9b9"
      />
      <path
        d="M180.56978,406.86329l14.18584,2.57924s41.26788-25.79242,43.84712-27.082-41.26788-23.21319-41.26788-23.21319l-29.66129,11.60659Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#ffb9b9"
      />
      <path
        d="M197.33486,359.1473l-18.623,7.28726,5.72679-24.05233-33.53015.43158s-10.317,73.07683,9.02735,73.07683a88.8289,88.8289,0,0,0,33.53015-6.44811l-.09761-.25219,1.38723.25219s41.26788-25.79242,43.84712-27.082S197.33486,359.1473,197.33486,359.1473Z"
        transform="translate(-74.49995 -44.90408)"
        opacity="0.1"
      />
      <circle cx="168.14483" cy="154.85357" r="36.82489" fill="#ffb9b9" />
      <path
        d="M210.08725,158.21409l-7.195-2.88049s15.04405-16.56264,35.97508-15.12235l-5.887-6.48111s14.39-5.76086,27.47182,9.36149c6.87684,7.9495,14.83344,17.29371,19.79355,27.81986h7.70536l-3.21593,7.08111,11.25575,7.08111-11.55293-1.272a39.18287,39.18287,0,0,1-1.09271,18.3393l.30967,5.59691s-13.39147-20.71937-13.39147-23.59986v7.20116s-7.195-6.481-7.195-10.80168l-3.92452,5.04082-1.96226-7.9213-24.20132,7.9213,3.92452-6.481-15.044,2.16034,5.887-7.9213s-17.0063,9.36149-17.66052,17.28279c-.654,7.92119-9.15708,18.00283-9.15708,18.00283l-3.92452-7.20115S191.11868,169.01576,210.08725,158.21409Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#2f2e41"
      />
      <path
        d="M274.71213,320.45867s-10.317,11.60659-10.317,28.37166l-68.34992-2.57924s-18.0547-2.57924-18.0547,10.317v12.89621s9.02735-3.86886,14.18584,0,60.61219,37.399,87.69424,24.50281,33.53015-63.19144,33.53015-63.19144S288.898,310.1417,274.71213,320.45867Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#ffb9b9"
      />
      <path
        d="M290.18759,255.97761l10.317-1.28962s20.63393,2.57924,18.05469,42.5575-3.86886,42.5575-3.86886,42.5575-27.082-24.50281-46.42636-12.89622Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#d0cde1"
      />
      <path
        d="M166.384,290.79738h-5.15848a47.77452,47.77452,0,0,0-10.317,29.66129,142.74183,142.74183,0,0,1-4.51368,34.175l40.62307-7.09292Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#d0cde1"
      />
      <rect
        x="731.50005"
        y="409.16623"
        width="2"
        height="51.92969"
        fill="#3f3d56"
      />
      <path
        d="M807,442c2.10742,5.6796,5.70313,12.72656,9.51172,17.09473L807,455.6543l-9.50977,3.44043C801.29883,454.72656,804.89453,447.6796,807,442Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <rect
        x="491.50005"
        y="364.16623"
        width="2"
        height="51.92969"
        fill="#3f3d56"
      />
      <path
        d="M567,397c2.10742,5.6796,5.70313,12.72656,9.51172,17.09473L567,410.6543l-9.50977,3.44043C561.29883,409.72656,564.89453,402.6796,567,397Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
      <rect
        x="823.50005"
        y="324.16623"
        width="2"
        height="51.92969"
        fill="#3f3d56"
      />
      <path
        d="M899,357c2.10742,5.6796,5.70313,12.72656,9.51172,17.09473L899,370.6543l-9.50977,3.44043C893.29883,369.72656,896.89453,362.6796,899,357Z"
        transform="translate(-74.49995 -44.90408)"
        fill="#3f3d56"
      />
    </svg>
  );
};

export default InvestmentIcon;
