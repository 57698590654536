import { call, put, takeLatest } from 'redux-saga/effects';
import { getLogs } from '../api';

function* getLogsSagas(action) {
  try {
    const request = yield call(getLogs, action.payload);
    yield put({
      type: 'GET_LOGS_SUCCESS',
      payload: request,
    });
  } catch (e) {
    yield put({ type: 'GET_LOGS_FAILURE', payload: e.message });
  }
}

function* LogsSagas() {
  yield takeLatest('GET_LOGS_REQUEST', getLogsSagas);
}
export default LogsSagas;
