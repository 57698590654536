import { connect } from "react-redux";
import { searchCompany } from "../actions/charts";
import SearchCompany from "../components/admin/SearchCompany";

const mapDispatchToProps = (dispatch) => {
  return {
    searchCompany: (token, query) => dispatch(searchCompany(token, query)),
  };
};

const mapStateToProps = (state) => {
  return {
    charts: state.charts,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchCompany);
